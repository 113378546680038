import { LanguageName } from 'types/LanguageName'
import { Level } from 'types/Level'
import { Project } from 'types/Project'
import { TrackName } from 'types/TrackName'

const getProjects: () => {
  projects: Project[]
  raspberryPiProjects: Project[]
  pythonProjects: Project[]
  christmasProjects: Project[]
  microBitProjects: Project[]
  storyProjects: Project[]
  printingProjects: Project[]
} = () => {
  const { intro, three, one, two } = Level
  const { introductionToScratch, introductionToPython, moreScratch, furtherScratch } = TrackName
  const { hardware, html, python, scratch, unity, makecode } = LanguageName

  const printingProjects: Project[] = [
    {
      language: hardware,
      level: [one],
      title: '3D Key Ring',
      url: 'https://projects.raspberrypi.org/en/projects/blockscad-coder-keyring',
      slug: 'blockscad-coder-keyring',
    },
    {
      language: hardware,
      level: [two],
      title: '3D Bug ',
      url: 'https://projects.raspberrypi.org/en/projects/blockscad-bug',
      slug: 'blockscad-bug',
    },
    {
      language: hardware,
      level: [three],
      title: '3d Pendant',
      url: 'https://projects.raspberrypi.org/en/projects/blockscad-pendant',
      slug: 'blockscad-pendant',
    },
  ]

  const storyProjects: Project[] = [
    {
      language: makecode,
      level: [one],
      title: 'Full of Stories',
      url: 'https://arcade.makecode.com/--skillmap#story',
      slug: 'full-of-stories',
      domain: 'arcade.makecode.com',
    },
    {
      language: scratch,
      level: [one],
      title: 'I made you a book',
      url: 'https://projects.raspberrypi.org/en/projects/i-made-you-a-book',
      slug: 'i-made-you-a-book',
    },
    {
      language: html,
      level: [one],
      title: 'Tell a story',
      url: 'https://projects.raspberrypi.org/en/projects/tell-a-story',
      slug: 'tell-a-story',
    },
    {
      language: makecode,
      level: [two],
      title: 'Code an Adventure',
      url: 'https://arcade.makecode.com/--skillmap#adventure',
      slug: 'code-an-adventure',
      domain: 'arcade.makecode.com',
    },
    {
      language: python,
      level: [two],
      title: 'Story Time',
      url: 'https://projects.raspberrypi.org/en/projects/storytime',
      slug: 'storytime',
    },
    {
      language: scratch,
      level: [three],
      title: 'Make an interactive book',
      url: 'https://projects.raspberrypi.org/en/projects/scratchpc-interactive-book/',
      slug: 'scratchpc-interactive-book',
    },
    {
      language: python,
      level: [three],
      title: 'Pride and Prejudice for zombies',
      url: 'https://projects.raspberrypi.org/en/projects/pride-prejudice-zombies',
      slug: 'pride-prejudice-zombies',
    },
  ]

  const raspberryPiProjects: Project[] = [
    {
      language: hardware,
      level: [intro],
      title: 'Getting started with Raspberry Pi',
      url: 'https://projects.raspberrypi.org/en/projects/raspberry-pi-getting-started',
      slug: 'raspberry-pi-getting-started',
    },
    {
      language: python,
      level: [intro],
      title: 'Getting started with the Sense HAT',
      url: 'https://projects.raspberrypi.org/en/projects/getting-started-with-the-sense-hat',
      slug: 'getting-started-with-the-sense-hat',
    },
    {
      language: python,
      level: [one],
      title: 'Countdown timer',
      url: 'https://projects.raspberrypi.org/en/projects/countdown-timer',
      slug: 'countdown-timer',
    },
    {
      language: python,
      level: [one],
      title: 'Random Sparkles',
      url: 'https://projects.raspberrypi.org/en/projects/sense-hat-random-sparkles',
      slug: 'sense-hat-random-sparkles',
    },
    {
      language: python,
      level: [one],
      title: 'Magic 8 Ball',
      url: 'https://projects.raspberrypi.org/en/projects/magic-8-ball',
      slug: 'magic-8-ball',
    },
    {
      language: python,
      level: [two],
      title: 'Flappy Astronaut',
      url: 'https://projects.raspberrypi.org/en/projects/flappy-astronaut',
      slug: 'flappy-astronaut',
    },
    {
      language: python,
      level: [three],
      title: 'SLUG!',
      url: 'https://projects.raspberrypi.org/en/projects/slug',
      slug: 'slug',
    },
    {
      language: python,
      level: [one],
      title: 'Astro Pi: Mission Zero',
      url: 'https://projects.raspberrypi.org/en/projects/astro-pi-mission-zero',
      slug: 'astro-pi-mission-zero',
    },
  ]

  const pythonProjects: Project[] = [
    {
      language: python,
      level: [one],
      title: 'Hello 🌍🌎🌏',
      url: 'https://projects.raspberrypi.org/en/projects/hello-world',
      slug: 'hello-world',
    },
    {
      language: python,
      level: [one],
      title: 'About Me',
      url: 'https://projects.raspberrypi.org/en/projects/about-me',
      slug: 'about-me',
    },
    {
      language: python,
      level: [one],
      title: 'Colourful Creations',
      url: 'https://projects.raspberrypi.org/en/projects/colourful-creations',
      slug: 'colourful-creations',
    },
    {
      language: python,
      level: [two],
      title: 'Guess The Number',
      url: 'https://projects.raspberrypi.org/en/projects/cd-beginner-python-sushi',
      slug: 'cd-beginner-python-sushi',
    },
    {
      language: python,
      level: [two],
      title: 'Turtle Race',
      url: 'https://projects.raspberrypi.org/en/projects/turtle-race',
      slug: 'turtle-race',
    },
    {
      language: python,
      level: [two],
      title: 'Story Time',
      url: 'https://projects.raspberrypi.org/en/projects/storytime',
      slug: 'storytime',
    },
    {
      language: python,
      level: [three],
      title: 'RPG',
      url: 'https://projects.raspberrypi.org/en/projects/rpg',
      slug: 'rpg',
    },
    {
      language: python,
      level: [three],
      title: 'Turtley Amazing',
      url: 'https://projects.raspberrypi.org/en/projects/turtley-amazing',
      slug: 'turtley-amazing',
    },
    {
      language: python,
      level: [three],
      title: 'Secret Agent Chat',
      url: 'https://projects.raspberrypi.org/en/projects/secret-agent-chat',
      slug: 'secret-agent-chat',
    },
  ]

  const christmasProjects: Project[] = [
    {
      language: python,
      level: [one],
      title: 'Scratch Cat goes skiing',
      url: 'https://projects.raspberrypi.org/en/projects/scratch-cat-goes-skiing',
      slug: 'scratch-cat-goes-skiing',
    },
    {
      language: python,
      level: [one],
      title: 'Jazzy jumpers',
      url: 'https://projects.raspberrypi.org/en/projects/jazzy-jumpers',
      slug: 'jazzy-jumpers',
    },
    {
      language: python,
      level: [one],
      title: 'Snowball fight',
      url: 'https://projects.raspberrypi.org/en/projects/snowball-fight',
      slug: 'snowball-fight',
    },
    {
      language: python,
      level: [two],
      title: 'Turtle snowflakes',
      url: 'https://projects.raspberrypi.org/en/projects/turtle-snowflakes',
      slug: 'turtle-snowflakes',
    },
    {
      language: python,
      level: [three],
      title: 'Code a carol',
      url: 'https://projects.raspberrypi.org/en/projects/code-a-carol',
      slug: 'code-a-carol',
    },
  ]

  const microBitProjects: Project[] = [
    {
      language: makecode,
      level: [one],
      title: 'Interactive badge',
      url: 'https://projects.raspberrypi.org/en/projects/interactive-badge',
      slug: 'interactive-badge',
    },
    {
      language: makecode,
      level: [one],
      title: 'Fortune Teller',
      url: 'https://projects.raspberrypi.org/en/projects/fortune-teller',
      slug: 'fortune-teller',
    },
    {
      language: makecode,
      level: [two],
      title: 'Reaction',
      url: 'https://projects.raspberrypi.org/en/projects/reaction',
      slug: 'reaction',
    },
    {
      language: python,
      level: [two],
      title: 'Reaction Game',
      url: 'https://microbit.org/projects/make-it-code-it/reaction-game',
      slug: 'reaction-game',
      domain: 'microbit.org',
    },
    {
      language: python,
      level: [three],
      title: 'Fireflies',
      url: 'https://microbit.org/projects/make-it-code-it/fireflies',
      slug: 'fireflies',
      domain: 'microbit.org',
    },
    {
      language: python,
      level: [three],
      title: 'micro:bit meteorologist',
      url: 'https://projects.raspberrypi.org/en/projects/microbit-meteorologist',
      slug: 'microbit-meteorologist',
    },
    {
      language: python,
      level: [three],
      title: 'Python data logger',
      url: 'https://microbit.org/projects/make-it-code-it/python-wireless-data-logger',
      slug: 'python-wireless-data-logger',
      domain: 'microbit.org',
    },
  ]

  const projects: Project[] = [
    {
      language: scratch,
      level: [one],
      title: 'Space talk',
      url: 'https://projects.raspberrypi.org/en/projects/space-talk',
      slug: 'space-talk',
      track: { name: introductionToScratch, position: 1 },
    },
    {
      language: scratch,
      level: [one],
      title: 'Catch the bus',
      url: 'https://projects.raspberrypi.org/en/projects/catch-the-bus',
      slug: 'catch-the-bus',
      track: { name: introductionToScratch, position: 2 },
    },
    {
      language: scratch,
      level: [one],
      title: 'Find the bug',
      url: 'https://projects.raspberrypi.org/en/projects/find-the-bug',
      slug: 'find-the-bug',
      track: { name: introductionToScratch, position: 3 },
    },
    {
      language: scratch,
      level: [one],
      title: 'Silly eyes',
      url: 'https://projects.raspberrypi.org/en/projects/silly-eyes',
      slug: 'silly-eyes',
      track: { name: introductionToScratch, position: 4 },
    },
    {
      language: scratch,
      level: [one],
      title: 'Surprise! animation',
      url: 'https://projects.raspberrypi.org/en/projects/surprise-animation',
      slug: 'surprise-animation',
      track: { name: introductionToScratch, position: 5 },
    },
    {
      language: scratch,
      level: [one],
      title: 'I made you a book',
      url: 'https://projects.raspberrypi.org/en/projects/i-made-you-a-book',
      slug: 'i-made-you-a-book',
      track: { name: introductionToScratch, position: 6 },
    },
    {
      language: scratch,
      level: [two],
      title: 'Broadcasting spells',
      url: 'https://projects.raspberrypi.org/en/projects/broadcasting-spells',
      slug: 'broadcasting-spells',
      track: { name: moreScratch, position: 1 },
    },
    {
      language: scratch,
      level: [two],
      title: 'Grow a dragonfly',
      url: 'https://projects.raspberrypi.org/en/projects/grow-a-dragonfly',
      slug: 'grow-a-dragonfly',
      track: { name: moreScratch, position: 2 },
    },
    {
      language: scratch,
      level: [two],
      title: 'Drum star',
      url: 'https://projects.raspberrypi.org/en/projects/drum-star',
      slug: 'drum-star',
      track: { name: moreScratch, position: 3 },
    },
    {
      language: scratch,
      level: [two],
      title: 'Next customer please',
      url: 'https://projects.raspberrypi.org/en/projects/next-customer-please',
      slug: 'next-customer-please',
      track: { name: moreScratch, position: 4 },
    },
    {
      language: scratch,
      level: [two],
      title: "Don't fall in!",
      url: 'https://projects.raspberrypi.org/en/projects/dont-fall-in',
      slug: 'dont-fall-in',
      track: { name: moreScratch, position: 5 },
    },
    {
      language: scratch,
      level: [two],
      title: 'This sprite needs you',
      url: 'https://projects.raspberrypi.org/en/projects/this-sprite-needs-you',
      slug: 'this-sprite-needs-you',
      track: { name: moreScratch, position: 6 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Nature rover',
      url: 'https://projects.raspberrypi.org/en/projects/nature-rover',
      slug: 'nature-rover',
      track: { name: furtherScratch, position: 1 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Puzzle room',
      url: 'https://projects.raspberrypi.org/en/projects/puzzle-room',
      slug: 'puzzle-room',
      track: { name: furtherScratch, position: 2 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Mandala',
      url: 'https://projects.raspberrypi.org/en/projects/mandala',
      slug: 'mandala',
      track: { name: furtherScratch, position: 3 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Swarms, schools, and flocks',
      url: 'https://projects.raspberrypi.org/en/projects/swarms-schools-flocks',
      slug: 'swarms-schools-flocks',
      track: { name: furtherScratch, position: 4 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Music maker',
      url: 'https://projects.raspberrypi.org/en/projects/music-maker',
      slug: 'music-maker',
      track: { name: furtherScratch, position: 5 },
    },
    {
      language: scratch,
      level: [three],
      title: 'Welcome to my world',
      url: 'https://projects.raspberrypi.org/en/projects/welcome-to-my-world',
      slug: 'welcome-to-my-world',
      track: { name: furtherScratch, position: 6 },
    },
    {
      language: python,
      level: [one],
      title: 'Hello 🌍🌎🌏',
      url: 'https://projects.raspberrypi.org/en/projects/hello-world',
      slug: 'hello-world',
      track: { name: introductionToPython, position: 1 },
    },
    {
      language: python,
      level: [one],
      title: 'Target practice',
      url: 'https://projects.raspberrypi.org/en/projects/target-practice',
      slug: 'target-practice',
      track: { name: introductionToPython, position: 2 },
    },
    {
      language: python,
      level: [one],
      title: 'Rocket launch',
      url: 'https://projects.raspberrypi.org/en/projects/rocket-launch',
      slug: 'rocket-launch',
      track: { name: introductionToPython, position: 3 },
    },
    {
      language: python,
      level: [one],
      title: 'Make a face',
      url: 'https://projects.raspberrypi.org/en/projects/make-a-face',
      slug: 'make-a-face',
      track: { name: introductionToPython, position: 4 },
    },
    {
      language: python,
      level: [one],
      title: "Don't collide!",
      url: 'https://projects.raspberrypi.org/en/projects/dont-collide',
      slug: 'dont-collide',
      track: { name: introductionToPython, position: 5 },
    },
    {
      language: python,
      level: [one],
      title: 'Powerful patterns',
      url: 'https://projects.raspberrypi.org/en/projects/powerful-patterns',
      slug: 'powerful-patterns',
      track: { name: introductionToPython, position: 6 },
    },
    {
      language: python,
      level: [one, two],
      title: 'Hour of Python - Turtles',
      url: 'https://trinket.io/challenges/holiday-tree.html',
      slug: 'hour-of-python-with-turtles',
      domain: 'hourofpython.com',
    },
    {
      language: python,
      level: [one],
      title: 'Hour of Python - Holiday Tree',
      url: 'https://trinket.io/challenges/holiday-tree.html',
      slug: 'hour-of-python-holiday-tree',
      domain: 'hourofpython.com',
    },
    {
      language: python,
      level: [one],
      title: 'Hour of Python - Bullseye',
      url: 'https://trinket.io/challenges/bullseye.html',
      slug: 'hour-of-python-bullseye',
      domain: 'hourofpython.com',
    },
    {
      language: python,
      level: [one],
      title: 'CodeCraft',
      url: 'https://projects.raspberrypi.org/en/projects/codecraft',
      slug: 'codecraft',
    },
    {
      language: unity,
      level: [three],
      title: 'Explore a 3D world',
      url: 'https://projects.raspberrypi.org/en/projects/explore-a-3d-world',
      slug: 'explore-a-3d-world',
    },
    {
      language: html,
      level: [one],
      title: 'Guide to my country 1.0',
      url: 'https://projects.raspberrypi.org/en/projects/cd-beginner-html-css-sushi',
      slug: 'cd-beginner-html-css-sushi',
    },
    {
      language: html,
      level: [one],
      title: 'Guide to my country 2.0',
      url: 'https://projects.raspberrypi.org/en/projects/cd-intermediate-html-css-sushi',
      slug: 'cd-intermediate-html-css-sushi',
    },
    {
      language: html,
      level: [one],
      title: 'Guide to my country 3.0',
      url: 'https://projects.raspberrypi.org/en/projects/cd-advanced-html-css-sushi',
      slug: 'cd-advanced-html-css-sushi',
    },
  ]

  return { projects, raspberryPiProjects, pythonProjects, christmasProjects, microBitProjects, storyProjects, printingProjects }
}

export default getProjects()
